<template>
  <div v-if="doc">
    <div class="buttons">
      <b-button
        tag="router-link"
        :to="{ name: 'failure', params: { id: doc.failure.id }}"
      >
        <b-icon
          icon="chevron-left"
        />
        {{ $t('common.navigation.go_to_failure') }}
      </b-button>
      <b-button
        tag="a"
        type="is-danger"
        icon-left="delete"
        @click="removePart"
      >
        {{ $t('common.delete') }}
      </b-button>
    </div>

    <!-- <progress-bar/> -->
    <div class="columns">
      <div class="column">
        <part
          ref="part"
        />
      </div>
      <div class="column">
        <replacement />
        <parent />
        <failure-state
          :object-doc="doc.failure"
          object-module="failures"
          :editable="false"
        />
        <failure-vehicle
          :object-doc="doc.failure"
          object-module="failures"
          :editable="false"
        />
        <files />
      </div>
    </div>
  </div>
</template>

<script>
import { toPairs } from 'lodash'
import moment from 'moment'

import Parent from './FailurePart/Parent'
import Part from './FailurePart/Part'
import Replacement from './FailurePart/Replacement'
import Files from '@/components/Files'

import FailureState from './Failure/State'
import FailureVehicle from './Failure/Vehicle'

export default {
  components: {
    Parent,
    Part,
    Replacement,
    Files,
    FailureState,
    FailureVehicle
  },
  async created () {
    await this.$store.dispatch('currentDoc/loadDoc')
    this.$store.dispatch('failures/fetchById', this.doc.failure.id)
    if(this.$store.getters['currentDoc/canUpdate'] && (store.getters["user/data"].id !== 'wVXjGxOeDbPGzm6KxtcXh6eP1Xl1')) {
      if(this.$refs.part.$refs.card) {
        this.$refs.part.$refs.card.isEditing = true
      }
    }
  },
  methods: {
    removePart () {
      const failurePart = this.doc
      const failureId = _.clone(this.doc.failure.id)
      if (failurePart.state !== 'received' || failurePart.failureSupply) {
        this.$buefy.toast.open({
          duration: 10000,
          message: this.$t('failureParts.form.remove_prepare'),
          position: 'is-bottom',
          type: 'is-warning'
        })
      } else {
        this.$store.dispatch(`failureParts/delete`, failurePart.id)
        this.$router.push({ name: 'failure', params: { id: failureId } })
      }
    }
  }

}
</script>
